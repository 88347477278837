<template>
  <b-row class="match-height">
    <!-- Payment -->
    <b-col
      lg="6"
      md="6"
    >
      <b-overlay
        :show="overlayStatusBalances"
        spinner-variant="primary"
        rounded="sm"
      >

          <b-card
            no-body
            class="card-payment"
            v-if="showData"
          >
            <b-card-header>
              <b-card-title>Pago a {{ dataBalance.companyName != null ? dataBalance.companyName : dataBalance.commissionAgentName }}</b-card-title>
              <b-card-title class="text-primary">
                Restante $ {{  formatterNumber(Math.abs(dataBalance.balance)) }}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <!-- form -->
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Operacion"
                      label-for="payment-card-number"
                      class="mb-2"
                    >
                      <v-select
                        :options="pendingPayments"
                        label="name"
                        input-id="invoice-data-paymentType"
                        :clearable="false"
                        :reduce="name => name.id"
                        v-model="paymentData.id"
                        @input="changeSelect($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label="Cantidad a pagar"
                      label-for="amount"
                      class="mb-2"
                    >
                      <b-form-input
                        @keypress="restrictNumber($event)"
                        id="amount"
                        type="number"
                        v-model="paymentData.amount"
                        :formatter="limitAmount"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      variant="primary"
                      @click="payBill"
                      :disabled="paymentData.amount==0"                
                    >
                      {{ btnTxt }} ${{ formatterNumber(paymentData.amount) }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!--/ form -->
            </b-card-body>
          </b-card>
      </b-overlay>
    </b-col>
    <!-- Transactions -->
    <b-col
      lg="6"
      md="6"
      v-if="showData"
    >
      <b-overlay
        :show="overlayStatusTransactions"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-header>
            <b-card-title>Transacciones</b-card-title>
          </b-card-header>

          <b-card-body>
            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="scroll-area"
            >
              <div
                v-for="transaction in transactionData"
                :key="transaction.idOrder"
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="transaction.avatarVariant"
                    >
                      <feather-icon
                        size="18"
                        :icon="transaction.avatar"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ transaction.user }}
                    </h6>
                    <small>{{ transaction.date }}</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                  :class="transaction.deduction ? 'text-danger' : 'text-success'"
                  style="margin-left: 3em"
                >
                  {{ transaction.payment }}
                </div>
              </div>
            </vue-perfect-scrollbar>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-col>

    <div v-if="!$router.currentRoute.params.paymentType">
      <Empty
        :no-button="true"
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Para ver esta pantalla selecciona alguna cuenta por cobrar o pagar."
      />
    </div>
  </b-row>
</template>


<style lang="scss" scoped>
  @import './paymentBills.css';
</style>

<style lang="scss">
  .scroll-area {
    height: 18em;
  }
</style> 

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BCardSubTitle, 
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, 
  BButton, BAlert, BTable, BOverlay, VBTooltip, BPagination, BTooltip,
  BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge
} from 'bootstrap-vue'
import Empty from '../components/empty-page/Empty'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import data from '@/router/routes/ui-elements'
import utils from '../../utils/utils.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import router from '@/router'





export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    vSelect,
    BTable,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BTooltip,

    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Empty,

    // 3rd party
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      overlayStatusBalances: true,
      overlayStatusTransactions: true,
      transactionData: [],
      dataBalance: [],
      pendingPayments: [],
      btnTxt: null,
      showData: false,
      maxAmount: 0,
      paymentData: {
        idOrder: null,
        idCommissionAgent: null,
        idCompany: null,
        id: null,
        amount: 0,
        idAction: null,
        completePayment: false,
        deadline: String(this.$moment().valueOf()),
        // action: "COMPRA"
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: true,
      },
    }
  },
  created(){
    if (router.currentRoute.params.paymentType == 'accountsPayable' || router.currentRoute.params.paymentType == 'accountsReceivable') {
      this.getCompanyBalances();
      this.getTransactionHistory();
      this.btnTxt = router.currentRoute.params.paymentType == 'accountsPayable' ? 'Pagar' : 'Cobrar';
    } else if (router.currentRoute.params.paymentType == 'middleMan') {
      this.getMiddleManBalances();
      this.getTransactionHistory();
      this.btnTxt = 'Pagar';
    } else if (router.currentRoute.params.paymentType == 'adjustmentBalance') {
      this.getAdjustmentBalances();
      this.getTransactionHistory();
      this.btnTxt = 'Pagar';
    }else {
      this.overlayStatusBalances = false;
      this.overlayStatusTransactions = false;
    }
  },
  methods: {
    getMiddleManBalances() {
      this.$axios.get(this.$services + 'orders/get_agent_balances', {
        params: { 
          idCommissionAgent: router.currentRoute.params.id,
          deadline: this.$moment().subtract(1, 'months').valueOf(),
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {

          this.overlayStatusBalances = false;
          let obj = {};

          this.dataBalance = res.data.data[0];
          
          if (this.dataBalance.nextPayments.length > 0) {
            this.showData = true;
            this.paymentData.idCommissionAgent = String(this.dataBalance.idCommissionAgent);
            this.paymentData.deadline = String(this.dataBalance.nextPayments[0].deadline);

            this.paymentData.id = this.dataBalance.nextPayments[0].deadline; // select id

            this.paymentData.amount = this.dataBalance.nextPayments[0].amount;
            this.maxAmount = this.dataBalance.nextPayments[0].amount;
            
            this.dataBalance.nextPayments.forEach(element => {

              let amount = this.formatterNumber(element.amount)

              obj = {
                ...element,
                name: '$' + amount + ' - ' + this.remainingDays(element.deadline),
                idCommissionAgent: String(this.dataBalance.idCommissionAgent),
                id: element.deadline,
              };

              this.pendingPayments.push(obj);
            });
          }
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    getCompanyBalances() {
      this.$axios.get(this.$services + 'orders/get_company_balances', {
        params: { 
          idCompany: router.currentRoute.params.id,
          type: router.currentRoute.params.paymentType == 'accountsPayable' ? 'COMPRA' : 'VENTA',
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {

          this.overlayStatusBalances = false;
          this.dataBalance = res.data.data[0];

          if (this.dataBalance.nextPayments.length > 0) {
            let obj = {};

            this.showData = true;

            this.paymentData.idOrder = String(this.dataBalance.nextPayments[0].idOrder);

            this.paymentData.id = this.dataBalance.nextPayments[0].idOrder; // select id

            this.paymentData.amount = this.dataBalance.nextPayments[0].amount;
            this.maxAmount = this.dataBalance.nextPayments[0].amount;
            
            this.dataBalance.nextPayments.forEach(element => {

              let amount = this.formatterNumber(element.amount)

              obj = {
                ...element,
                name: 'Orden '+ element.idOrder +' - $' + amount + ' - ' + this.remainingDays(element.deadline),
                id: element.idOrder,
              };

              this.pendingPayments.push(obj);
            });
          }
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    getAdjustmentBalances() {
      this.$axios.get(this.$services + 'orders/get_adjustment_balances', {
        params: { 
          idCompany: router.currentRoute.params.id,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {

          this.overlayStatusBalances = false;
          let obj = {};

          this.dataBalance = res.data.data[0];
          
          if (this.dataBalance.balance < 0) {

            this.showData = true;

            this.paymentData.id = this.dataBalance.idCompany; // select id
            this.paymentData.idCompany = this.dataBalance.idCompany; // select id
            this.paymentData.amount = Math.abs(this.dataBalance.balance);

            this.maxAmount = this.paymentData.amount;
            let amount = this.formatterNumber(this.paymentData.amount)

            obj = {
                name: '$' + amount,
                id: this.dataBalance.idCompany,
              };
            this.pendingPayments.push(obj);
          }
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    getTransactionHistory() {
      let type = null;
      if (router.currentRoute.params.paymentType == 'accountsPayable')
        type = 'COMPRA';
      else if (router.currentRoute.params.paymentType == 'accountsReceivable')
        type = 'VENTA';
      else if (router.currentRoute.params.paymentType == 'adjustmentBalance')
        type = 'AJUSTE';

      this.$axios.get(this.$services + 'orders/get_transaction_history', {
        params: { 
          idCompany: router.currentRoute.params.id,
          companyTransactions: router.currentRoute.params.paymentType == 'accountsPayable' || router.currentRoute.params.paymentType == 'accountsReceivable' || router.currentRoute.params.paymentType == 'adjustmentBalance' ? true : false,
          type: type,
          idCommissionAgent: router.currentRoute.params.id,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {

          this.overlayStatusTransactions = false;
          let obj = {};

          if (router.currentRoute.params.paymentType == 'accountsPayable' || router.currentRoute.params.paymentType == 'accountsReceivable') {
            res.data.data.forEach(element => {
              obj = {
                idOrder: element.date,
                avatar: 'CheckIcon',
                avatarVariant: 'light-success',
                user: 'Abono a orden #' + element.idOrder + ' autorizado por ' + element.userName,
                date: this.$moment(parseInt(element.date)).format("DD/MM/YYYY h:mm a"),
                payment: router.currentRoute.params.paymentType == 'accountsPayable' ? "-$" + this.formatterNumber(element.amount) : "+$" + this.formatterNumber(element.amount),
                deduction: router.currentRoute.params.paymentType == 'accountsPayable' ? true : false,
              }
              this.transactionData.push(obj);
            });
          } else if (router.currentRoute.params.paymentType == 'middleMan') {
            res.data.data.forEach(element => {
              obj = {
                avatar: 'CheckIcon',
                avatarVariant: 'light-success',
                user: 'Abono al ' + this.$moment(parseInt(element.deadline)).format("DD/MM/YYYY") + ' autorizado por ' + element.userName,
                date: this.$moment(parseInt(element.date)).format("DD/MM/YYYY h:mm a"),
                payment: "-$" + this.formatterNumber(element.amount),
                deduction: true,
              }
              this.transactionData.push(obj);
            });
          } else if (router.currentRoute.params.paymentType == 'adjustmentBalance') {
            res.data.data.forEach(element => {
              obj = {
                avatar: 'CheckIcon',
                avatarVariant: 'light-success',
                user: 'Abono autorizado por ' + element.userName,
                date: this.$moment(parseInt(element.date)).format("DD/MM/YYYY h:mm a"),
                payment: "-$" + this.formatterNumber(element.amount),
                deduction: true,
              }
              this.transactionData.push(obj);
            });
          }
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    changeSelect(id) {
      if (router.currentRoute.params.paymentType == 'company') {
        let paymentData = this.pendingPayments.find(payment => payment.idOrder == id);
        this.maxAmount = paymentData.amount;
        this.paymentData.amount = paymentData.amount;
        this.paymentData.idOrder = String(paymentData.idOrder);
        this.paymentData.id = paymentData.idOrder; // select id

      } else if (router.currentRoute.params.paymentType == 'middleMan') {
        let paymentData = this.pendingPayments.find(payment => payment.deadline == id);
        this.maxAmount = paymentData.amount;
        this.paymentData.amount = paymentData.amount;
        this.paymentData.idCommissionAgent = String(paymentData.idCommissionAgent);
        this.paymentData.deadline = String(paymentData.deadline);
        this.paymentData.id = paymentData.deadline; // select id
      }
    },
    formatterNumber(value){
      return utils.numberWithCommas(value == "" ? 0 : value)
    },
    payBill() {

      this.overlayStatusBalances = true;
      this.overlayStatusTransactions = true;

      this.paymentData.completePayment = this.paymentData.amount == this.maxAmount ? true : false;
      this.paymentData.adjustment = router.currentRoute.params.paymentType == 'adjustmentBalance' ? true : false;
      this.paymentData.idAction = router.currentRoute.params.paymentType == 'accountsPayable' || router.currentRoute.params.paymentType == 'adjustmentBalance' ? '4' : '5';
      this.paymentData.amount = String(this.paymentData.amount);
      this.paymentData.date = String(this.$moment().valueOf());

      this.$axios.post(this.$services+'orders/create_payment', {
        paymentData: this.paymentData,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.transactionData = [];
          this.dataBalance = [];
          this.pendingPayments = [];
          this.showToast('success', String('¡Pago realizado con éxito!'));

          if (router.currentRoute.params.paymentType == 'accountsPayable' || router.currentRoute.params.paymentType == 'accountsReceivable') {
            this.getCompanyBalances();
            this.getTransactionHistory();
          } else if (router.currentRoute.params.paymentType == 'middleMan') {
            this.getMiddleManBalances();
            this.getTransactionHistory();
          } else if (router.currentRoute.params.paymentType == 'adjustmentBalance') {
            this.getAdjustmentBalances();
            this.getTransactionHistory();
          }
        } else {
        }
      }).catch(error => {
        this.showToast('danger', String(error));
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    remainingDays(timeStamp) {
      // Diferencia de días
      let expression;
      let days = this.diffDays(timeStamp);
      
      (Math.abs(days) == 1) ? expression = ' día' : expression = ' días';

      if (days < 0) { // Delay
        return 'Retrasado por ' + Math.abs(days) + expression;
      } else if (days == 0) { // Important
        return 'Pagar hoy';
      } else if (days >= 1 && days <= 3) { // Warning
        return 'Pagar en menos de ' + days + expression;
      } else { // Not warnign
        return 'Pagar en menos de ' + days + expression;
      }
    },
    priority(timeStamp) {

      let days = this.diffDays(timeStamp);

      if (days <= 1) { // Delay
        return 'danger';
      } else if (days >= 2 && days <= 3) { // Warning
        return 'warning';
      } else { // Not warnign
        return 'primary';
      }
    },
    diffDays(timeStamp) {

      // Set 00:00 both dates
      let today = this.$moment().set({hour:0,minute:0,second:0,millisecond:0});
      let deadline = this.$moment(timeStamp).set({hour:0,minute:0,second:0,millisecond:0});

      let diffDays = deadline.diff(today, 'days');

      return diffDays;
    },
    restrictNumber(event){ // Solo numeros pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
      }
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    limitAmount(e){
      if (parseInt(e) >= this.maxAmount) {
        return this.maxAmount;
      } else if (parseInt(e) < 0) {
        return 0;
      } else {
        return e;
      }
    },
  },
}
</script>
